import axios from 'axios'
import { SimplifiedPhoenixUser } from '../../app/modules/auth'
import { PipedriveAccount, PipedriveDeal, PipedrivePerson } from '../routes/PartnersRoutes'

export const BASE_API_URL = `${process.env.REACT_APP_API_URL}`
const API_URL = `${BASE_API_URL}/admin/api/v1`

export class HighLevelRequestData {
  inserted_at: string
  updated_at: string

  account?: {
    inserted_at: string
    updated_at: string
    unique_identifier: string
    name: string
    external_ghl_site_url?: string
  }
  location?: {
    inserted_at: string
    updated_at: string
    unique_identifier: string
    name: string
  }

  public_data: any
  request_body: string
  request_query_params: any
  request_url: string
  response_status: number
  response_text: string
  unique_identifier: string

  constructor(data: any) {
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.account = data.account
    this.location = data.location
    this.public_data = data.public_data
    this.request_body = data.request_body
    this.request_query_params = data.request_query_params
    this.request_url = data.request_url
    this.response_status = data.response_status
    this.response_text = data.response_text
    this.unique_identifier = data.unique_identifier
  }
}

export class DashboardHighLevelData {
  highlevel_requests: HighLevelRequestData[]

  constructor(data: any) {
    this.highlevel_requests = data.highlevel_requests.map((item: any) => {
      return new HighLevelRequestData(item)
    })
  }
}

export class PassareRequestData {
  inserted_at: string
  updated_at: string
  unique_identifier: string

  public_data: any
  request_body: any
  request_information: any

  event_id: string
  trigger_type: string
  parsed_payload: any

  email_address?: string
  phone_numbers?: {
    home_phone: string | null
    work_phone: string | null
    mobile_phone: string | null
  }
  address?: {
    zip: string
    city: string
    state: string
    county: string | null
    borough: string
    country: string
    village: string
    address1: string
    address2: string | null
    address3: string | null
    address4: string | null
    latitude: number
    township: string
    longitude: number
    identifier: string
    address_uuid: string
    other_township: string
    inside_city_limits: boolean | null
  }

  constructor(data: any) {
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.unique_identifier = data.unique_identifier
    this.public_data = data.public_data
    this.request_body = data.request_body
    this.request_information = data.request_information
    this.event_id = data.event_id
    this.trigger_type = data.trigger_type
    this.parsed_payload = data.parsed_payload
    this.email_address = data.email_address || undefined
    this.phone_numbers = data.phone_numbers || undefined
    this.address = data.address || undefined
  }
}

export class DashboardPassareData {
  passare_requests: PassareRequestData[]

  constructor(data: any) {
    this.passare_requests = data.passare_requests.map((item: any) => {
      return new PassareRequestData(item)
    })
  }
}

export class PassareOrganization {
  unique_identifier: string
  organization_name: string
  organization_uuid: string
  public_data: any
  inserted_at: string
  updated_at: string
  private_data: {
    frm_linkage_information?: {
      account_unique_identifier: string
      frm_location_id: string
    }
  }

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.organization_name = data.organization_name
    this.organization_uuid = data.organization_uuid
    this.public_data = data.public_data
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.private_data = data.private_data
  }
}

class PassareAcquaintancePublicDataAcquaintanceResult {
  acquaintance_types?: string[]
  acquaintance_uuid?: string
  address_uuid?: string
  birth_address_uuid?: string
  birth_date?: string
  contact_information_uuid?: string
  display_name?: string
  do_not_contact?: boolean
  first_name?: string
  gender?: string
  is_deceased?: boolean
  last_name?: string
  last_name_at_birth?: string
  middle_name?: string
  other_relationship_description?: string
  preferred_name_attribute?: string
  relationship?: string
  spouse_acquaintance_uuid?: string
  suffix?: string
  title?: string

  constructor(data: any) {
    this.acquaintance_types = data.acquaintance_types || undefined
    this.acquaintance_uuid = data.acquaintance_uuid || undefined
    this.address_uuid = data.address_uuid || undefined
    this.birth_address_uuid = data.birth_address_uuid || undefined
    this.birth_date = data.birth_date || undefined
    this.contact_information_uuid = data.contact_information_uuid || undefined
    this.display_name = data.display_name || undefined
    this.do_not_contact = data.do_not_contact || undefined
    this.first_name = data.first_name || undefined
    this.gender = data.gender || undefined
    this.is_deceased = data.is_deceased || undefined
    this.last_name = data.last_name || undefined
    this.last_name_at_birth = data.last_name_at_birth || undefined
    this.middle_name = data.middle_name || undefined
    this.other_relationship_description = data.other_relationship_description || undefined
    this.preferred_name_attribute = data.preferred_name_attribute || undefined
    this.relationship = data.relationship || undefined
    this.spouse_acquaintance_uuid = data.spouse_acquaintance_uuid || undefined
    this.suffix = data.suffix || undefined
    this.title = data.title || undefined
  }
}

class PassareAcquaintancePublicDataPhoneNumbers {
  home_phone?: string
  mobile_phone?: string
  work_phone?: string

  constructor(data: any) {
    this.home_phone = data.home_phone || undefined
    this.mobile_phone = data.mobile_phone || undefined
    this.work_phone = data.work_phone || undefined
  }
}

class PassareAcquaintancePublicDataAddress {
  address1?: string
  address2?: string
  address3?: string
  address4?: string
  address_uuid?: string
  borough?: string
  city?: string
  country?: string
  county?: string
  identifier?: string
  inside_city_limits?: boolean
  latitude?: number
  longitude?: number
  other_township?: string
  state?: string
  township?: string
  village?: string
  zip?: string

  constructor(data: any) {
    this.address1 = data.address1 || undefined
    this.address2 = data.address2 || undefined
    this.address3 = data.address3 || undefined
    this.address4 = data.address4 || undefined
    this.address_uuid = data.address_uuid || undefined
    this.borough = data.borough || undefined
    this.city = data.city || undefined
    this.country = data.country || undefined
    this.county = data.county || undefined
    this.identifier = data.identifier || undefined
    this.inside_city_limits = data.inside_city_limits || undefined
    this.latitude = data.latitude || undefined
    this.longitude = data.longitude || undefined
    this.other_township = data.other_township || undefined
    this.state = data.state || undefined
    this.township = data.township || undefined
    this.village = data.village || undefined
    this.zip = data.zip || undefined
  }
}

class PassareAcquaintancePublicData {
  acquaintance_result?: PassareAcquaintancePublicDataAcquaintanceResult
  address?: PassareAcquaintancePublicDataAddress
  email_address?: string
  phone_numbers?: PassareAcquaintancePublicDataPhoneNumbers

  constructor(data: any) {
    this.acquaintance_result = data.acquaintance_result ? new PassareAcquaintancePublicDataAcquaintanceResult(data.acquaintance_result) : undefined
    this.address = data.address ? new PassareAcquaintancePublicDataAddress(data.address) : undefined
    this.email_address = data.email_address || undefined
    this.phone_numbers = data.phone_numbers ? new PassareAcquaintancePublicDataPhoneNumbers(data.phone_numbers) : undefined
  }
}

class PassareAcquaintancePrivateData {
  ghl_contact?: {
    ghl_company_id: string,
    ghl_location_id: string,
    ghl_contact_id: string
  }

  constructor(data: any) {
    this.ghl_contact = data.ghl_contact || undefined
  }
}

export class PassareCasePerson {
  birth_date?: string
  decedent_uuid: string
  first_name: string
  gender?: string
  last_name: string
  middle_name?: string
  suffix?: string
  title?: string

  constructor(data: any) {
    this.birth_date = data.birth_date || undefined
    this.decedent_uuid = data.decedent_uuid
    this.first_name = data.first_name
    this.gender = data.gender || undefined
    this.last_name = data.last_name
    this.middle_name = data.middle_name || undefined
    this.suffix = data.suffix || undefined
    this.title = data.title || undefined
  }
}

export class PassareCasePersonAddress {
  address_uuid: string
  borough?: string
  city: string
  country: string
  county?: string
  identifier: string
  inside_city_limits?: boolean
  latitude: number
  longitude: number
  other_township?: string
  state: string
  township?: string
  village?: string

  constructor(data: any) {
    this.address_uuid = data.address_uuid
    this.borough = data.borough || undefined
    this.city = data.city
    this.country = data.country
    this.county = data.county || undefined
    this.identifier = data.identifier
    this.inside_city_limits = data.inside_city_limits || undefined
    this.latitude = data.latitude
    this.longitude = data.longitude
    this.other_township = data.other_township || undefined
    this.state = data.state
    this.township = data.township || undefined
    this.village = data.village || undefined
  }
}

export class PassareCasePersonDecedent {
  address?: PassareCasePersonAddress
  death?: any
  highest_rank?: any
  obituary?: any
  person: PassareCasePerson
  veteran: boolean

  constructor(data: any) {
    this.address = data.address ? new PassareCasePersonAddress(data.address) : undefined
    this.death = data.death || undefined
    this.highest_rank = data.highest_rank || undefined
    this.obituary = data.obituary || undefined
    this.person = new PassareCasePerson(data.person)
    this.veteran = data.veteran
  }
}

export class PassareCasePersonDecedentAnimal {
  name: string
  type: string
  breed: string
  death: string
  gender: string
  weight: string
  decedent_uuid: string

  constructor(data: any) {
    this.name = data.name
    this.type = data.type
    this.breed = data.breed
    this.death = data.death
    this.gender = data.gender
    this.weight = data.weight
    this.decedent_uuid = data.decedent_uuid
  }
}

export class PassareCaseResult {
  case_assigned_to: string
  case_identifier: string
  case_types: string[]
  case_status: 'New' | 'In Progress' | 'Closed' | null
  case_url: string
  case_uuid: string
  client_service_type_name?: string
  custom_fields: any[]
  decedent_animal?: PassareCasePersonDecedentAnimal
  decedent_person?: PassareCasePersonDecedent
  events: any[]
  memorial_donations: any[]
  pc_payment_link?: string

  constructor(data: any) {
    this.case_assigned_to = data.case_assigned_to
    this.case_identifier = data.case_identifier
    this.case_types = data.case_types
    this.case_status = data.case_status
    this.case_url = data.case_url
    this.case_uuid = data.case_uuid
    this.client_service_type_name = data.client_service_type_name || undefined
    this.custom_fields = data.custom_fields
    this.decedent_animal = data.decedent_animal ? new PassareCasePersonDecedentAnimal(data.decedent_animal) : undefined
    this.decedent_person = data.decedent_person ? new PassareCasePersonDecedent(data.decedent_person) : undefined
    this.events = data.events
    this.memorial_donations = data.memorial_donations
    this.pc_payment_link = data.pc_payment_link || undefined
  }
}

export class PassareCase {
  unique_identifier: string
  inserted_at: string
  updated_at: string
  public_data: {
    case_result?: PassareCaseResult
  }
  case_signed_at: string
  branch_name: string

  disposition_data?: {
    lot: string
    block: string
    grave: string
    section: string
    cemetery: {
      name: string
      uuid: string
      address: {
        city: string
        state: string
        county: string
        borough: string
        country: string
        village: string
        latitude: number
        township: string
        longitude: number
        identifier: string
        address_uuid: string
        other_township: string
        inside_city_limits: boolean
      }
      contact_information: {
        fax: string
        email: string
        home_phone: string
        work_phone: string
        mobile_phone: string
        do_not_contact: boolean
        contact_information_uuid: string
      }
    }
    crematory: any
    permit_number: string
    disposition_date: string
    disposition_time: string
    disposition_type: string
    disposition_address: {
      fsa: string
      ldu: string
      zip: string
      city: string
      state: string
      county: string
      borough: string
      country: string
      village: string
      address1: string
      address2: string
      address3: string
      address4: string
      township: string
      other_township: string
    }
  }

  decedent_data?: {
    ssn: string
    veteran: boolean
    employer: string
    industry: string
    race_uuid: string
    death_date: string
    occupation: string
    citizenship: string
    address_uuid: string
    display_name: string
    acquaintances: {
      acquaintance_uuid: string
    }[]
    decedent_uuid: string
    alternate_name: string
    education_uuid: string
    ethnicity_uuid: string
    marital_status: string
    birth_address_uuid: string
    death_address_uuid: string
  }

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.public_data = {
      case_result: data.public_data.case_result ? new PassareCaseResult(data.public_data.case_result) : undefined
    }
    this.case_signed_at = data.case_signed_at
    this.branch_name = data.branch_name
    this.disposition_data = data.disposition_data || undefined
    this.decedent_data = data.decedent_data || undefined
  }
}

export class PassareAcquaintance {
  unique_identifier: string
  inserted_at: string
  updated_at: string
  passare_organization: PassareOrganization

  case_item: PassareCase

  public_data: PassareAcquaintancePublicData
  private_data: PassareAcquaintancePrivateData

  getDecedentFullName(): string {
    // {acquaintance.case_item.public_data.case_result.decedent_person.person.first_name} {acquaintance.case_item.public_data.case_result.decedent_person.person.last_name}
    // or
    // {`Animal: ${acquaintance.case_item.public_data.case_result.decedent_animal.name} - ${acquaintance.case_item.public_data.case_result.decedent_animal.breed}`}
    // or
    // {`Unknown Decedent`}
    //
    if (!this.case_item.public_data.case_result) {
      return 'Unknown Decedent'
    }
    if (this.case_item.decedent_data && this.case_item.decedent_data.display_name) {
      return this.case_item.decedent_data.display_name
    }
    if (this.case_item.public_data.case_result.decedent_person) {
      const name = this.case_item.public_data.case_result.decedent_person.person.first_name + ' ' + this.case_item.public_data.case_result.decedent_person.person.last_name
      return name
    } else if (this.case_item.public_data.case_result.decedent_animal) {
      return `Animal: ${this.case_item.public_data.case_result.decedent_animal.name} - ${this.case_item.public_data.case_result.decedent_animal.breed}`
    }
    return 'Unknown Decedent'
  }

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.passare_organization = new PassareOrganization(data.passare_organization)
    this.case_item = new PassareCase(data.case)
    this.public_data = new PassareAcquaintancePublicData(data.public_data)
    this.private_data = new PassareAcquaintancePrivateData(data.private_data)
  }
}

interface SimplifiedPipedriveDeal {
  unique_identifier: string

  inserted_at: string
  updated_at: string

  last_updated_from_pipedrive_at: string

  deceased_full_name: string
  funeral_home_name: string
  fca_org_id: string
  fca_estate_specialist_full_name: string
  preneed_interest_level: string | null
  preneed_notes: string | null
  lost_reason: string | null

  ghl_contact?: {
    ghl_company_id: string,
    ghl_location_id: string,
    ghl_contact_id: string
  }

}

interface SimplifiedPipedrivePerson {
  lead_full_name: string
  unique_identifier: string
  inserted_at: string
  updated_at: string
  lead_email_addresses: {
    label: string
    primary: boolean
    value: string
  }[]
  lead_phone_numbers: {
    label: string
    primary: boolean
    value: string
  }[]
  relationship_to_deceased: string
}

interface SimplifiedOnlineArrangement {
  unique_identifier: string

  inserted_at: string
  updated_at: string

  public_data: any

  private_data: {
    ghl_contact?: {
      ghl_company_id: string,
      ghl_location_id: string,
      ghl_contact_id: string
    }
  }
}

interface SimplifiedServiceLocation {
  unique_identifier: string
  name: string
}

export class FullCircleLead {
  deal: SimplifiedPipedriveDeal
  person: SimplifiedPipedrivePerson

  constructor(data: any) {
    this.deal = data.deal as SimplifiedPipedriveDeal
    this.person = data.person as SimplifiedPipedrivePerson
  }
}

export class AbandonedCartLead {
  arrangement: SimplifiedOnlineArrangement
  location: SimplifiedServiceLocation

  constructor(data: any) {
    this.arrangement = data.arrangement as SimplifiedOnlineArrangement
    this.location = data.location as SimplifiedServiceLocation
  }
}

export class EventAdministrator {
  inserted_at: string
  unique_identifier: string
  event: {
    event_name: string
  }
  person_full_name: string
  person_phone_number: string
  public_data: {
    person_email_address?: string
    person_relationship_to_deceased?: string
    person_interested_in_pre_planning?: boolean
    latest_highlevel_contact_id?: string
  }
  location: {
    private_data?: {
      linked_to_frm_location_id?: string
    }
    unique_identifier: string
    name: string
    account: {
      unique_identifier: string
      name: string
    }
  }
  inserted_by_partner?: {
    name: string
  }

  constructor(data: any) {
    this.inserted_at = data.inserted_at
    this.unique_identifier = data.unique_identifier
    this.event = data.event
    this.person_full_name = data.person_full_name
    this.person_phone_number = data.person_phone_number
    this.public_data = data.public_data
    this.location = data.location
    this.inserted_by_partner = data.inserted_by_partner
  }
}

export class EventCheckInRecord {
  inserted_at: string
  unique_identifier: string
  event: {
    event_name: string
  }
  visitor_full_name: string
  visitor_phone_number: string
  public_data: {
    visitor_email_address?: string
    visitor_relationship_to_deceased?: string
    visitor_interested_in_pre_planning?: boolean
    latest_highlevel_contact_id?: string
  }
  location: {
    private_data: {
      linked_to_frm_location_id?: string
    }
    unique_identifier: string
    name: string
    account: {
      unique_identifier: string
      name: string
    }
  }
  inserted_by_partner?: {
    name: string
  }

  constructor(data: any) {
    this.inserted_at = data.inserted_at
    this.unique_identifier = data.unique_identifier
    this.event = data.event
    this.visitor_full_name = data.visitor_full_name
    this.visitor_phone_number = data.visitor_phone_number
    this.public_data = data.public_data
    this.location = data.location
    this.inserted_by_partner = data.inserted_by_partner
  }
}


export class DirectMailReply {
  inserted_at: string
  public_data: {
    respondent_person: {
      full_name: string
      birth_date: string
      email_address: string
      phone_number1: string
      phone_number2: string
    }
    respondent_address: {
      street_address_line_1: string
      street_address_line_2: string
      city: string
      state: string
      postal_code: string
    }
    image_urls: string[]
    latest_highlevel_contact_id?: string
    additional_metadata?: {
      mailer_code?: string
      order_number?: string
    }
  }
  unique_identifier: string
  location: {
    private_data: {
      linked_to_frm_location_id?: string
    }
    unique_identifier: string
    name: string
    account: {
      name: string
      unique_identifier: string
    }
  }
  inserted_by_partner?: {
    name: string
  }

  constructor(data: any) {
    this.inserted_at = data.inserted_at
    this.public_data = data.public_data
    this.unique_identifier = data.unique_identifier
    this.location = data.location
    this.inserted_by_partner = data.inserted_by_partner
  }
}

export class DashboardEventAdministratorsData {
  event_administrators: EventAdministrator[]
  total_event_administrators: number

  constructor(data: any) {
    this.event_administrators = data.event_administrators.map((item: any) => {
      return new EventAdministrator(item)
    })
    this.total_event_administrators = data.total_event_administrators
  }
}

export class DashboardEventCheckInRecordsData {
  event_check_in_records: EventCheckInRecord[]
  total_event_check_in_records: number

  constructor(data: any) {
    this.event_check_in_records = data.event_check_in_records.map((item: any) => {
      return new EventCheckInRecord(item)
    })
    this.total_event_check_in_records = data.total_event_check_in_records
  }
}

export class DashboardDirectMailRepliesData {
  direct_mail_replies: DirectMailReply[]
  total_direct_mail_replies: number

  constructor(data: any) {
    this.direct_mail_replies = data.direct_mail_replies.map((item: any) => {
      return new DirectMailReply(item)
    })
    this.total_direct_mail_replies = data.total_direct_mail_replies
  }
}

export class APIPublishableKey {
  key: string

  constructor(data: any) {
    this.key = data.key
  }
}

export class APISecretKey {
  key_last_4: string
  key_prefix: string

  constructor(data: any) {
    this.key_last_4 = data.key_last_4
    this.key_prefix = data.key_prefix
  }
}

export class APIKeys {
  publishable_keys: APIPublishableKey[]
  secret_keys: APISecretKey[]

  constructor(data: any) {
    this.publishable_keys = data.publishable_keys.map((item: any) => {
      return new APIPublishableKey(item)
    })
    this.secret_keys = data.secret_keys.map((item: any) => {
      return new APISecretKey(item)
    })
  }
}


export class AccountPrivateData {
  linked_to_frm_company_id?: string

  constructor(data: any) {
    this.linked_to_frm_company_id = data.linked_to_frm_company_id || undefined
  }
}

export class Account {
  inserted_at: string
  updated_at: string
  unique_identifier: string
  name: string

  //
  // ! If copying this class for a public-facing / non-admin
  // ! application, remove the api_keys property.
  //
  api_keys?: APIKeys

  // ! Same with private_data
  private_data?: AccountPrivateData

  external_ghl_site_url?: string

  last_event_inserted_at?: string
  last_event_administrator_inserted_at?: string
  last_event_check_in_record_inserted_at?: string
  last_direct_mail_reply_inserted_at?: string

  constructor(data: any) {
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.unique_identifier = data.unique_identifier
    this.name = data.name
    this.api_keys = data.api_keys ? new APIKeys(data.api_keys) : undefined
    this.private_data = data.private_data ? new AccountPrivateData(data.private_data) : undefined
    this.external_ghl_site_url = data.external_ghl_site_url || undefined
    this.last_event_inserted_at = data.last_event_inserted_at || undefined
    this.last_event_administrator_inserted_at = data.last_event_administrator_inserted_at || undefined
    this.last_event_check_in_record_inserted_at = data.last_event_check_in_record_inserted_at || undefined
    this.last_direct_mail_reply_inserted_at = data.last_direct_mail_reply_inserted_at || undefined
  }
}

export class SimplifiedAccountUserMembership {
  // everything except the user, as this indicates for now just the user's relationship
  // the account. Redundant with AccountUserMembership but admins don't have this set
  // so it seems to make sense to separate them.
  unique_identifier: string
  inserted_at: string
  updated_at: string

  public_data: {
    role: 'account_admin' | 'marketer'
  }

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.public_data = data.public_data
  }

}

export class AccountUserMembership {
  unique_identifier: string
  inserted_at: string
  updated_at: string

  user: SimplifiedPhoenixUser

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.user = new SimplifiedPhoenixUser(data.user)
  }
}

export class ServiceLocationPrivateData {
  linked_to_frm_location_id?: string

  date_of_service_frm_custom_field_id?: string
  deceased_person_name_frm_custom_field_id?: string
  deceased_person_date_of_birth_frm_custom_field_id?: string
  deceased_person_date_of_death_frm_custom_field_id?: string

  relationship_to_deceased_frm_custom_field_id?: string
  interested_in_pre_planning_frm_custom_field_id?: string
  pre_planning_frm_custom_selected_picklist_option?: string

  latest_received_mailer_image_frm_custom_field_id?: string

  latest_metadata_mailer_code_frm_custom_field_id?: string
  latest_metadata_order_number_frm_custom_field_id?: string

  constructor(data: any) {
    this.linked_to_frm_location_id = data.linked_to_frm_location_id || undefined
    this.date_of_service_frm_custom_field_id = data.date_of_service_frm_custom_field_id || undefined
    this.deceased_person_name_frm_custom_field_id = data.deceased_person_name_frm_custom_field_id || undefined
    this.deceased_person_date_of_birth_frm_custom_field_id = data.deceased_person_date_of_birth_frm_custom_field_id || undefined
    this.deceased_person_date_of_death_frm_custom_field_id = data.deceased_person_date_of_death_frm_custom_field_id || undefined
    this.relationship_to_deceased_frm_custom_field_id = data.relationship_to_deceased_frm_custom_field_id || undefined
    this.interested_in_pre_planning_frm_custom_field_id = data.interested_in_pre_planning_frm_custom_field_id || undefined
    this.pre_planning_frm_custom_selected_picklist_option = data.pre_planning_frm_custom_selected_picklist_option || undefined
    this.latest_received_mailer_image_frm_custom_field_id = data.latest_received_mailer_image_frm_custom_field_id || undefined
    this.latest_metadata_mailer_code_frm_custom_field_id = data.latest_metadata_mailer_code_frm_custom_field_id || undefined
    this.latest_metadata_order_number_frm_custom_field_id = data.latest_metadata_order_number_frm_custom_field_id || undefined
  }
}


export class ServiceLocation {
  inserted_at: string
  updated_at: string
  unique_identifier: string
  name: string
  account: Account

  //
  // ! If copying this class for a public-facing / non-admin
  // ! application, remove the private_data property.
  //

  private_data?: ServiceLocationPrivateData

  last_event_inserted_at?: string
  last_event_administrator_inserted_at?: string
  last_event_check_in_record_inserted_at?: string
  last_direct_mail_reply_inserted_at?: string

  constructor(data: any) {
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.unique_identifier = data.unique_identifier
    this.name = data.name
    this.account = new Account(data.account)
    this.private_data = data.private_data ? new ServiceLocationPrivateData(data.private_data) : undefined
    this.last_event_inserted_at = data.last_event_inserted_at || undefined
    this.last_event_administrator_inserted_at = data.last_event_administrator_inserted_at || undefined
    this.last_event_check_in_record_inserted_at = data.last_event_check_in_record_inserted_at || undefined
    this.last_direct_mail_reply_inserted_at = data.last_direct_mail_reply_inserted_at || undefined
  }
}

export class AvailableFRMLocation {
  name: string
  _id: string
  address: string

  constructor(data: any) {
    this.name = data.name
    this._id = data._id
    this.address = data.address
  }
}

export class AvailableCustomFRMField {
  dataType: "TEXT" | "RADIO" | "LARGE_TEXT" | "DATE" | "SINGLE_OPTIONS" | "FILE_UPLOAD" | "PHONE" | "CHECKBOX" | "MONETORY" | "NUMERICAL"
  dateAdded: string
  documentType: string
  fieldKey: string
  id: string
  locationId: string
  model: string
  name: string
  parentId: string
  picklistOptions: string[]
  placeholder: string
  position: number

  constructor(data: any) {
    this.dataType = data.dataType
    this.dateAdded = data.dateAdded
    this.documentType = data.documentType
    this.fieldKey = data.fieldKey
    this.id = data.id
    this.locationId = data.locationId
    this.model = data.model
    this.name = data.name
    this.parentId = data.parentId
    this.picklistOptions = data.picklistOptions
    this.placeholder = data.placeholder
    this.position = data.position
  }

}


export class Partner {
  inserted_at: string
  updated_at: string
  unique_identifier: string
  name: string

  constructor(data: any) {
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.unique_identifier = data.unique_identifier
    this.name = data.name
  }
}

export class PartnerAPIKey {
  unique_identifier: string
  key_last_4: string
  key_prefix: string
  inserted_at: string
  updated_at: string
  last_used_at?: string

  // only available after initial creation
  secret_key?: string

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.key_last_4 = data.key_last_4
    this.key_prefix = data.key_prefix
    this.secret_key = data.secret_key || undefined
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.last_used_at = data.last_used_at || undefined
  }
}

export class PartnerAccountMembership {
  unique_identifier: string
  inserted_at: string
  updated_at: string

  account: Account

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.inserted_at = data.inserted_at
    this.updated_at = data.updated_at
    this.account = new Account(data.account)
  }
}

export class EventByDayItem {
  full_date: string; // "2019-01-01"
  unique_events: number;

  constructor(data: any) {
    this.full_date = data.full_date;
    this.unique_events = data.unique_events;
  }
}

export class EventAdministratorByDayItem {
  full_date: string; // "2019-01-01"
  unique_administrators: number;

  constructor(data: any) {
    this.full_date = data.full_date;
    this.unique_administrators = data.unique_administrators;
  }
}

export class EventCheckInRecordByDayItem {
  full_date: string; // "2019-01-01"
  unique_check_in_records: number;

  constructor(data: any) {
    this.full_date = data.full_date;
    this.unique_check_in_records = data.unique_check_in_records;
  }
}

export class DirectMailRepliesByDayItem {
  full_date: string; // "2019-01-01"
  unique_direct_mail_replies: number;

  constructor(data: any) {
    this.full_date = data.full_date;
    this.unique_direct_mail_replies = data.unique_direct_mail_replies;
  }
}

export class DashboardGraphData {
  total_events: number;
  events_by_day: EventByDayItem[];

  total_event_administrators: number;
  event_administrators_by_day: EventAdministratorByDayItem[];

  total_event_check_in_records: number;
  event_check_in_records_by_day: EventCheckInRecordByDayItem[];

  total_direct_mail_replies: number;
  direct_mail_replies_by_day: DirectMailRepliesByDayItem[];

  constructor(data: any) {
    this.total_events = data.total_events;
    this.events_by_day = data.events_by_day.map((item: any) => new EventByDayItem(item));

    this.total_event_administrators = data.total_event_administrators;
    this.event_administrators_by_day = data.event_administrators_by_day.map((item: any) => new EventAdministratorByDayItem(item));

    this.total_event_check_in_records = data.total_event_check_in_records;
    this.event_check_in_records_by_day = data.event_check_in_records_by_day.map((item: any) => new EventCheckInRecordByDayItem(item));

    this.total_direct_mail_replies = data.total_direct_mail_replies;
    this.direct_mail_replies_by_day = data.direct_mail_replies_by_day.map((item: any) => new DirectMailRepliesByDayItem(item));
  }

  // Example formatted label method for total events
  getFormattedEventsLabel(): string {
    const totalEvents = this.total_events || 0;
    return `${totalEvents.toLocaleString()} event${totalEvents === 1 ? '' : 's'}`;
  }

  // Add similar methods for event administrators and event check-in records as needed
  getFormattedEventAdministratorsLabel(): string {
    const totalEventAdministrators = this.total_event_administrators || 0;
    return `${totalEventAdministrators.toLocaleString()} event administrator${totalEventAdministrators === 1 ? '' : 's'}`;
  }

  getFormattedEventCheckInRecordsLabel(): string {
    const totalEventCheckInRecords = this.total_event_check_in_records || 0;
    return `${totalEventCheckInRecords.toLocaleString()} event check-in record${totalEventCheckInRecords === 1 ? '' : 's'}`;
  }

  // total_direct_mail_replies:

  getFormattedDirectMailRepliesLabel(): string {
    const totalDirectMailReplies = this.total_direct_mail_replies || 0;
    return `${totalDirectMailReplies.toLocaleString()} direct mail repl${totalDirectMailReplies === 1 ? 'y' : 'ies'}`;
  }
}

export class DashboardListDataEvent {
  full_date: string
  events: {
    event: {
      account_unique_identifier: string,
      event_datetime: string,
      unique_identifier: string,
      event_name: string,
      location: {
        private_data?: {
          linked_to_frm_location_id?: string
        }
        name: string,
        unique_identifier: string
      },
    },
    check_in_records: any,
    event_administrators: any
  }[]

  constructor(data: any) {
    this.full_date = data.full_date
    this.events = data.events.map((event: any) => {
      return {
        event: event.event,
        check_in_records: event.check_in_records,
        event_administrators: event.event_administrators
      }
    })
  }
}

export class DashboardEventsListData {
  // Constructor takes an array, where the array is a list
  // of objects that look like {full_data, events}
  // where each event (if it exists) is an object that has
  // {event, check_in_records, and event_administrators}

  total_counts: {
    total_unique_events: number,
    total_admins: number,
    total_check_ins: number
  }

  events: DashboardListDataEvent[]

  constructor(data: any) {
    this.total_counts = data.total_counts
    this.events = data.results_by_date.map((item: any) => {
      return new DashboardListDataEvent(item)
    })
  }
}

export class DashboardListDataDirectMailReply {
  full_date: string
  replies: {
    reply: {
      account_unique_identifier: string,
      reply_inserted_at: string,
      reply_unique_identifier: string,
      location: {
        unique_identifier: string,
        name: string,
        linked_to_frm_location_id?: string
      },
      public_data?: {
        latest_highlevel_contact_id?: string
        additional_metadata?: {
          mailer_code?: string
          order_number?: string
        }
        image_urls?: string[]
      }
    }
  }[]

  constructor(data: any) {
    this.full_date = data.full_date
    this.replies = data.replies.map((reply: any) => {
      return {
        reply: reply.reply
      }
    })
  }
}

export class DashboardDirectMailRepliesListData {
  total_counts: {
    total_unique_replies: number,
  }

  direct_mail_replies: DashboardListDataDirectMailReply[]

  constructor(data: any) {
    this.total_counts = data.total_counts
    this.direct_mail_replies = data.results_by_date.map((item: any) => {
      return new DashboardListDataDirectMailReply(item)
    })
  }
}

export type ServiceLocationWithAccessLevel = {
  serviceLocation: ServiceLocation
  isSelected: boolean
}

export function getDashboardGraphData(
  datetimeStartUnixTimestamp: number,
  datetimeEndUnixTimestamp: number,
  onlyShowServiceLocationUniqueIdentifier?: string | null
) {
  return axios.get(`${API_URL}/main-dashboard-graph?start_unix_ts=${datetimeStartUnixTimestamp}&end_unix_ts=${datetimeEndUnixTimestamp}` + (onlyShowServiceLocationUniqueIdentifier ? `&only_show_service_location_unique_identifier=${onlyShowServiceLocationUniqueIdentifier}` : '')).then((res) => {
    return new DashboardGraphData(res.data.data)
  })
}

export function getDashboardListDataForEvents(datetimeStartUnixTimestamp: number, datetimeEndUnixTimestamp: number) {
  return axios.get(`${API_URL}/main-dashboard-list-for-events?start_unix_ts=${datetimeStartUnixTimestamp}&end_unix_ts=${datetimeEndUnixTimestamp}`).then((res) => {
    if (!res.data?.data?.results_by_date) {
      throw new Error('Malformed response (results_by_date)')
    }
    if (!res.data?.data?.total_counts) {
      throw new Error('Malformed response (total_counts)')
    }
    return new DashboardEventsListData(res.data.data)
  })
}

export function getDashboardListDataForDirectMailReplies(datetimeStartUnixTimestamp: number, datetimeEndUnixTimestamp: number) {
  return axios.get(`${API_URL}/main-dashboard-list-direct-mail-replies?start_unix_ts=${datetimeStartUnixTimestamp}&end_unix_ts=${datetimeEndUnixTimestamp}`).then((res) => {
    if (!res.data?.data?.results_by_date) {
      throw new Error('Malformed response')
    }
    if (!res.data?.data?.total_counts) {
      throw new Error('Malformed response')
    }
    return new DashboardDirectMailRepliesListData(res.data.data)
  })
}

export function getDashboardHighLevelRequests({
  limit,
  includeTokenRequests
}: {
  limit: number,
  includeTokenRequests: boolean
}) {
  return axios.get(`${API_URL}/dashboard/highlevel-requests?limit=${limit}&include_token_requests=${includeTokenRequests}`).then((res) => {
    if (!res.data.data.highlevel_requests) {
      throw new Error('Malformed response')
    }
    return new DashboardHighLevelData(res.data.data)
  })
}

export function getHighLevelRequestsWithPage({
  page,
  limit,
  includeTokenRequests
}: {
  page: number,
  limit: number,
  includeTokenRequests: boolean
}) {
  return axios.get(`${API_URL}/dashboard/highlevel-requests?page=${page}&limit=${limit}&include_token_requests=${includeTokenRequests}`).then((res) => {
    return res.data.data.highlevel_requests.map((item: any) => {
      return new HighLevelRequestData(item)
    })
  })
}

export function getPassareRequests({
  limit,
  page,
  includeTokenRequests
}: {
  limit: number,
  page: number,
  includeTokenRequests: boolean
}) {
  return axios.get(`${API_URL}/dashboard/passare-requests?limit=${limit}&page=${page}`).then((res) => {
    return new DashboardPassareData(res.data.data)
  })
}

export function getAllEventAdministratorLeads({
  limit,
  showLeadsRegardlessofGHLStatus = false,
  filteringByLocationUniqueIdentifier = null
}: {
  limit: number,
  showLeadsRegardlessofGHLStatus: boolean
  filteringByLocationUniqueIdentifier: string | null
}) {
  return axios.get(`${API_URL}/dashboard/all-event-administrators?limit=${limit}&show_leads_regardless_of_ghl_status=${showLeadsRegardlessofGHLStatus}&filtering_by_location_unique_identifier=${filteringByLocationUniqueIdentifier || ''}`)
    .then((res) => res.data)
    .then((res) => {
      if (!res.data.event_administrators || typeof res.data.total_event_administrators !== 'number') {
        throw new Error('Malformed response')
      }
      return new DashboardEventAdministratorsData(res.data)
    })
}

export function getAllEventCheckInRecords({
  limit,
  showLeadsRegardlessofGHLStatus = false,
  filteringByLocationUniqueIdentifier = null
}: {
  limit: number,
  showLeadsRegardlessofGHLStatus: boolean
  filteringByLocationUniqueIdentifier: string | null
}) {
  return axios.get(`${API_URL}/dashboard/all-event-check-in-records?limit=${limit}&show_leads_regardless_of_ghl_status=${showLeadsRegardlessofGHLStatus}&filtering_by_location_unique_identifier=${filteringByLocationUniqueIdentifier || ''}`)
    .then((res) => res.data)
    .then((res) => {
      if (!res.data.event_check_in_records || typeof res.data.total_event_check_in_records !== 'number') {
        throw new Error('Malformed response')
      }
      return new DashboardEventCheckInRecordsData(res.data)
    })
}

export function getAllDirectMailReplies({
  limit,
  showLeadsRegardlessofGHLStatus = false,
  filteringByLocationUniqueIdentifier = null
}: {
  limit: number,
  showLeadsRegardlessofGHLStatus: boolean,
  filteringByLocationUniqueIdentifier: string | null
}) {
  return axios.get(`${API_URL}/dashboard/all-direct-mail-replies?limit=${limit}&show_leads_regardless_of_ghl_status=${showLeadsRegardlessofGHLStatus}&filtering_by_location_unique_identifier=${filteringByLocationUniqueIdentifier || ''}`)
    .then((res) => res.data)
    .then((res) => {
      if (!res.data.direct_mail_replies || typeof res.data.total_direct_mail_replies !== 'number') {
        throw new Error('Malformed response')
      }
      return new DashboardDirectMailRepliesData(res.data)
    })
}

export function getAllPassareAcquaintances({
  limit,
  page,
  filteringByLocationUniqueIdentifier = null
}: {
  limit: number,
  page: number,
  filteringByLocationUniqueIdentifier: string | null
}): Promise<{
  total_passare_acquaintances: number,
  passare_acquaintances: PassareAcquaintance[]
}> {
  return axios.get(`${API_URL}/dashboard/all-passare-acquaintances?limit=${limit}&filtering_by_location_unique_identifier=${filteringByLocationUniqueIdentifier || ''}&page=${page}`)
    .then((res) => res.data)
    .then((res) => {
      if (!res.data.passare_acquaintances) {
        throw new Error('Malformed response')
      }
      return {
        total_passare_acquaintances: res.data.total_passare_acquaintances,
        passare_acquaintances: res.data.passare_acquaintances.map((item: any) => {
          return new PassareAcquaintance(item)
        }) as PassareAcquaintance[]
      }
    })
}

export function getAllFullCircleLeads({
  limit,
  page,
  filter
}: {
  limit: number,
  page: number,
  filter: {
    org_ids: string[],
    preneed_options: string[],
    only_unsynced_with_360: boolean,
    include_leads_regardless_of_preneed_status: boolean
  }
}) {
  return axios.get(`${API_URL}/dashboard/all-full-circle-leads?limit=${limit}&page=${page}&org_ids=${filter.org_ids.join(',')}&preneed_options=${filter.preneed_options.join(',')}&only_unsynced_with_360=${filter.only_unsynced_with_360}&include_leads_regardless_of_preneed_status=${filter.include_leads_regardless_of_preneed_status}`)
    .then((res) => res.data)
    .then((res) => {
      if (!res.data.full_circle_leads || typeof res.data.total_full_circle_leads !== 'number') {
        throw new Error('Malformed response')
      }
      return {
        total_full_circle_leads: res.data.total_full_circle_leads,
        full_circle_leads: res.data.full_circle_leads.map((item: any) => {
          return new FullCircleLead(item)
        }) as FullCircleLead[]
      }
    })
}

export function getAllAbandonedCartLeads({
  limit,
  page,
  filter
}: {
  limit: number,
  page: number,
  filter: {
    location_unique_ids: string[],
  }
}) {
  return axios.get(`${API_URL}/dashboard/all-abandoned-cart-leads?limit=${limit}&page=${page}&location_unique_ids=${filter.location_unique_ids.join(',')}`)
    .then((res) => res.data)
    .then((res) => {
      if (!res.data.abandoned_cart_leads || typeof res.data.total_abandoned_cart_leads !== 'number') {
        throw new Error('Malformed response')
      }
      return {
        total_abandoned_cart_leads: res.data.total_abandoned_cart_leads,
        abandoned_cart_leads: res.data.abandoned_cart_leads.map((item: any) => {
          return new AbandonedCartLead(item)
        }) as AbandonedCartLead[]
      }
    })
}

export function getAllPipedriveAccounts(onlyPartnerAccountUniqueId?: string) {
  return axios.get(`${API_URL}/get-pipedrive-accounts?partner_unique_identifier=${onlyPartnerAccountUniqueId || ''}`)
    .then((res) => res.data)
    .then((res) => {
      if (!res.data.pipedrive_accounts) {
        throw new Error('Malformed response')
      }
      return res.data.pipedrive_accounts as PipedriveAccount[]
    })
}

export function getAllPipedriveDeals(data: any) {
  return axios.post(`${API_URL}/get-pipedrive-deals`, data)
    .then((res) => res.data)
    .then((res) => {
      if (!res.data.pipedrive_deals) {
        throw new Error('Malformed response')
      }
      return {
        deals: res.data.pipedrive_deals as PipedriveDeal[],
        totalCount: res.data.total_count
      }
    })
}

export function getAllPipedrivePersons() {
  return axios.get(`${API_URL}/get-pipedrive-persons`)
    .then((res) => res.data)
    .then((res) => {
      if (!res.data.pipedrive_persons) {
        throw new Error('Malformed response')
      }
      return res.data.pipedrive_persons as PipedrivePerson[]
    })
}

export function syncLinkManuallyByUniqueIdentifier(uniqueIdentifier: string) {
  return axios.post(`${API_URL}/dashboard/sync-link-manually`, { unique_identifier: uniqueIdentifier }).then((res) => {
    return res.data.data
  })
}

export function getAccounts() {
  return axios.get(`${API_URL}/accounts`).then((res) => {
    return (res.data?.data.accounts || []).map((item: any) => {
      return new Account(item)
    })
  })
}

export function getAccountMemberships(accountUniqueIdentifier: string, role: 'account_admin' | 'marketer') {
  const route = role === 'account_admin' ? '/accounts/get-account-admins' : '/accounts/get-marketers'
  return axios.get(`${API_URL}${route}?account_unique_identifier=${accountUniqueIdentifier}`).then((res) => {
    if (!res.data.data.memberships) {
      throw new Error('Malformed response')
    }
    return res.data.data.memberships.map((item: any) => {
      return new AccountUserMembership(item)
    })
  })
}

export function getLocations() {
  return axios.get(`${API_URL}/locations`)
    .then((res) => res.data)
    .then((res) => {
      if (!res.data.locations) {
        throw new Error('Malformed response')
      }
      return res.data.locations.map((item: any) => {
        return new ServiceLocation(item)
      })
    })
}

export function getPartners() {
  return axios.get(`${API_URL}/partners`).then((res) => {
    return (res.data?.data.partners || []).map((item: any) => {
      return new Partner(item)
    })
  })
}

export function createPartner(data: any) {
  return axios.post(`${API_URL}/partners/create`, data).then((res) => {
    return new Partner(res.data.data.partner)
  })
}

export function sendPartnerPipedriveInvitation(partnerUniqueIdentifier: string, data: any) {
  return axios.post(`${API_URL}/partners/send-pipedrive-invitation?partner_unique_identifier=${partnerUniqueIdentifier}`, data).then((res) => {
    return res.data.data
  })
}

export function getPartnerByUniqueIdentifier(uniqueIdentifier: string) {
  return axios.get(`${API_URL}/partners/retrieve?unique_identifier=${uniqueIdentifier}`).then((res) => {
    return new Partner(res.data.data.partner)
  })
}

export function getPartnerApiKeys(uniqueIdentifier: string) {
  return axios.get(`${API_URL}/partners/get-api-keys?unique_identifier=${uniqueIdentifier}`).then((res) => {
    return res.data.data.api_keys
  })
}

export function createPartnerApiKey(uniqueIdentifier: string) {
  return axios.post(`${API_URL}/partners/create-api-key`, { unique_identifier: uniqueIdentifier }).then((res) => {
    if (!res.data.data.api_key) {
      throw new Error('Malformed response')
    }
    return new PartnerAPIKey(res.data.data.api_key)
  })
}

export function getPartnerPermissions(uniqueIdentifier: string) {
  return axios.get(`${API_URL}/partners/get-permissions?unique_identifier=${uniqueIdentifier}`).then((res) => {
    if (!res.data.data.account_memberships) {
      throw new Error('Malformed response')
    }
    return res.data.data.account_memberships.map((item: any) => {
      return new PartnerAccountMembership(item)
    })
  })
}

export function createPartnerPermission(uniqueIdentifier: string, data: any) {
  return axios.post(`${API_URL}/partners/create-permission`, { unique_identifier: uniqueIdentifier, ...data }).then((res) => {
    if (!res.data.data.account_membership || typeof res.data.data.created !== 'boolean') {
      throw new Error('Malformed response')
    }
    return {
      created: res.data.data.created,
      accountMembership: new PartnerAccountMembership(res.data.data.account_membership)
    }
  })
}

export function deletePermissionByUniqueId(uniqueIdentifier: string) {
  return axios.post(`${API_URL}/partners/delete-permission`, { partner_account_membership_unique_identifier: uniqueIdentifier }).then((res) => {
    return {
      success: true
    }
  })
}

export function deleteApiKeyByUniqueId(uniqueIdentifier: string) {
  return axios.post(`${API_URL}/partners/delete-api-key`, { partner_secret_key_unique_identifier: uniqueIdentifier }).then((res) => {
    return {
      success: true
    }
  })
}

export function getServiceLocations() {
  return axios.get(`${API_URL}/service-locations`).then((res) => {
    return (res.data?.data.service_locations || []).map((item: any) => {
      return new ServiceLocation(item)
    })
  })
}

export function createServiceLocation(data: any) {
  return axios.post(`${API_URL}/service-locations/create`, data).then((res) => {
    return new ServiceLocation(res.data.data.service_location)
  })
}

export function getServiceLocationByUniqueIdentifier(uniqueIdentifier: string) {
  return axios
    .get(`${API_URL}/service-locations/retrieve?unique_identifier=${uniqueIdentifier}`)
    .then((res) => {
      return new ServiceLocation(res.data.data.service_location)
    })
}

export function getAllFRMLocationsForServiceLocationUniqueIdentifier(uniqueIdentifier: string) {
  return axios
    .get(`${API_URL}/service-locations/get-all-frm-locations-for-service-location?unique_identifier=${uniqueIdentifier}`)
    .then((res) => {
      return res.data.data.frm_locations.map((item: any) => {
        return new AvailableFRMLocation(item)
      })
    })
}

export function getAllFRMLocationsForAccountUniqueIdentifier(uniqueIdentifier: string) {
  return axios
    .get(`${API_URL}/accounts/get-all-frm-locations-for-account?unique_identifier=${uniqueIdentifier}`)
    .then((res) => {
      return res.data.data.frm_locations.map((item: any) => {
        return new AvailableFRMLocation(item)
      })
    })
}

export function getPassareOrganizationByUniqueIdentifier(uniqueIdentifier: string) {
  return axios.get(`${API_URL}/passare/get-organization?unique_identifier=${uniqueIdentifier}`).then((res) => {
    if (!res.data.data.passare_organization) {
      throw new Error('Malformed response')
    }
    return new PassareOrganization(res.data.data.passare_organization)
  })
}

export function getCustomFieldsAvailableForPassareOrganizationByUniqueIdentifier(uniqueIdentifier: string, ghlLocationId: string) {
  return axios
    .get(`${API_URL}/passare/organizations/${uniqueIdentifier}/get-custom-fields?ghl_location_id=${ghlLocationId}`)
    .then((res) => {
      if (!res.data.data?.result?.customFields) {
        throw new Error('Malformed response')
      }
      return res.data.data.result.customFields.map((item: any) => {
        return new AvailableCustomFRMField(item)
      })
    })
}

export function selectFRMLocationForPassareOrganizationByUniqueIdentifier(data: any) {
  return axios
    .post(`${API_URL}/passare/select-frm-location`, data)
    .then((res) => {
      return new PassareOrganization(res.data.data.passare_organization)
    })
}

export function getAccountByUniqueIdentifier(uniqueIdentifier: string) {
  return axios
    .get(`${API_URL}/accounts/retrieve?unique_identifier=${uniqueIdentifier}`)
    .then((res) => {
      // Note ... myAccount will be null in cases where a user is an admin
      // This is because admins are basically global superusers and creating a mock / fake
      // membership doesn't seem to be the right approach.
      const account = new Account(res.data.data.account)
      return {
        account,
        myAccountMembership: res.data.data.my_account_membership ? new SimplifiedAccountUserMembership(res.data.data.my_account_membership) : null
      }
    })
}

export function getWarningsForAccountByUniqueIdentifier(uniqueIdentifier: string) {
  return axios
    .get(`${API_URL}/accounts/get-warnings?unique_identifier=${uniqueIdentifier}`)
    .then((res) => res.data)
    .then((res) => {
      if (!res.data.locations_missing_assignment || !Array.isArray(res.data.locations_missing_assignment)) {
        throw new Error('Malformed response')
      }
      return {
        locationsMissingAssignment: res.data.locations_missing_assignment.map((item: any) => {
          return new ServiceLocation(item)
        })
      }
    })
}

export function linkAccountToFRMByUniqueIdentifier(data: any) {
  return axios
    .post(`${API_URL}/accounts/link-to-frm`, data)
    .then((res) => {
      return new Account(res.data.data.account)
    })
}

export function disableAccountLinkToFRM(uniqueIdentifier: string) {
  return axios
    .post(`${API_URL}/accounts/disable-link-to-frm`, { unique_identifier: uniqueIdentifier })
    .then((res) => {
      return new Account(res.data.data.account)
    })
}

export function reInviteAccountUserMembership(uniqueIdentifier: string) {
  return axios
    .post(`${API_URL}/accounts/re-invite-user`, { account_user_membership_unique_identifier: uniqueIdentifier })
    .then((res) => {
      return res.data.data
    })
}

export function removeAccountUserMembership(uniqueIdentifier: string) {
  return axios
    .post(`${API_URL}/accounts/remove-user-membership`, { account_user_membership_unique_identifier: uniqueIdentifier })
    .then((res) => {
      return res.data.data
    })
}

export function selectFRMLocationIdForServiceLocationByUniqueIdentifier(data: any) {
  return axios
    .post(`${API_URL}/service-locations/select-location-id`, data)
    .then((res) => {
      return new ServiceLocation(res.data.data.service_location)
    })
}

export function disableFRMLinkForServiceLocationByUniqueIdentifier(uniqueIdentifier: string) {
  return axios
    .post(`${API_URL}/service-locations/disable-link-to-frm`, { unique_identifier: uniqueIdentifier })
    .then((res) => {
      return new ServiceLocation(res.data.data.service_location)
    })
}

export function createContactForServiceLocationByUniqueIdentifier(data: any) {
  return axios
    .post(`${API_URL}/service-locations/create-contact`, data)
    .then((res) => {
      return res.data.data
    })
}

export function getCustomFieldsAvailableForServiceLocationByUniqueIdentifier(uniqueIdentifier: string) {
  return axios
    .get(`${API_URL}/service-locations/get-custom-fields?unique_identifier=${uniqueIdentifier}`)
    .then((res) => {
      return res.data.data.custom_fields.map((item: any) => {
        return new AvailableCustomFRMField(item)
      })
    })
}

export function updateServiceLocationDetailsByUniqueIdentifier(data: any) {
  return axios
    .post(`${API_URL}/service-locations/update-details`, data)
    .then((res) => {
      return new ServiceLocation(res.data.data.service_location)
    })
}

export function updateCustomFieldsForServiceLocationByUniqueIdentifier(data: any) {
  return axios
    .post(`${API_URL}/service-locations/update-custom-fields`, data)
    .then((res) => {
      return res.data.data
    })
}

export function getServiceLocationEventsByUniqueIdentifier(uniqueIdentifier: string, isForExport: boolean = false) {
  return axios
    .get(`${API_URL}/service-locations/get-events?unique_identifier=${uniqueIdentifier}&is_for_export=${isForExport ? 'yes' : 'no'}`)
    .then((res) => {
      return res.data.data.events
    })
}

export function getServiceLocationEventAdministratorsByUniqueIdentifier(uniqueIdentifier: string, isForExport: boolean = false) {
  return axios
    .get(`${API_URL}/service-locations/get-event-administrators?unique_identifier=${uniqueIdentifier}&is_for_export=${isForExport ? 'yes' : 'no'}`)
    .then((res) => {
      return res.data.data.event_administrators
    })
}

export function getServiceLocationEventCheckInRecordsByUniqueIdentifier(uniqueIdentifier: string, isForExport: boolean = false) {
  return axios
    .get(`${API_URL}/service-locations/get-event-check-in-records?unique_identifier=${uniqueIdentifier}&is_for_export=${isForExport ? 'yes' : 'no'}`)
    .then((res) => {
      return res.data.data.event_check_in_records
    })
}

export function getServiceLocationDirectMailRepliesByUniqueIdentifier(uniqueIdentifier: string, isForExport: boolean = false) {
  return axios
    .get(`${API_URL}/service-locations/get-direct-mail-replies?unique_identifier=${uniqueIdentifier}&is_for_export=${isForExport ? 'yes' : 'no'}`)
    .then((res) => {
      return res.data.data.direct_mail_replies
    })
}

export function addUserToAccountByUniqueIdentifier(data: any) {
  return axios
    .post(`${API_URL}/accounts/add-user`, data)
    .then((res) => {
      if (typeof res.data?.data?.created !== 'boolean') {
        throw new Error('Malformed response')
      }
      return res.data.data.created
    })
}

export function addMarketerToAccountByUniqueIdentifier(data: any) {
  return axios
    .post(`${API_URL}/accounts/add-marketer`, data)
    .then((res) => {
      if (typeof res.data?.data?.created !== 'boolean') {
        throw new Error('Malformed response')
      }
      return res.data.data.created
    })
}

export function getListOfServiceLocationsWithAccessLevelForAccountUserMembershipUniqueIdentifier(accountUniqueIdentifier: string, accountUserMembershipUniqueIdentifier: string) {
  return axios
    .get(`${API_URL}/accounts/get-service-locations-with-access-level?account_unique_identifier=${accountUniqueIdentifier}&account_user_membership_unique_identifier=${accountUserMembershipUniqueIdentifier}`)
    .then((res) => {
      //We expect membership:
      if (!res.data.data.membership) {
        throw new Error('Malformed response')
      }
      // we expect available_service_locations and selected_service_locations
      if (!res.data.data.available_service_locations || !res.data.data.selected_service_locations) {
        throw new Error('Malformed response')
      }
      const selectedServiceLocationUniqueIdentifiers = res.data.data.selected_service_locations.map((item: any) => item.unique_identifier)
      return {
        membership: new AccountUserMembership(res.data.data.membership),
        locationsWithAccessLevel: res.data.data.available_service_locations.map((item: any) => {
          return {
            serviceLocation: new ServiceLocation(item),
            isSelected: selectedServiceLocationUniqueIdentifiers.includes(item.unique_identifier)
          } as ServiceLocationWithAccessLevel
        }).filter((item: any) => {
          // We have this additional filter because the back-end returns all service locations, but we only are interested in the ones for the account
          return item.serviceLocation.account.unique_identifier === accountUniqueIdentifier
        }) as ServiceLocationWithAccessLevel[]
      }
    })
}

export function giveAccessToServiceLocationForAccountUserMembershipUniqueIdentifier(data: any) {
  return axios
    .post(`${API_URL}/accounts/give-access-to-service-location`, data)
    .then((res) => {
      return res.data.data
    })
}

export function takeAccessFromServiceLocationForAccountUserMembershipUniqueIdentifier(data: any) {
  return axios
    .post(`${API_URL}/accounts/take-access-from-service-location`, data)
    .then((res) => {
      return res.data.data
    })
}

export function getAvailableFullCircleOptions() {
  return axios
    .get(`${API_URL}/full-circle/get-available-options`)
    .then((res) => {
      return {
        org_options: res.data.data.org_options
      }
    })
}